import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import icon from "../../static/favicons/favicon.ico"
import defaultCard from "../../static/card.jpg"

const Seo = ({
  description,
  lang,
  meta,
  image: metaImage,
  title,
  slug,
  url,
  published,
}) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          siteDescription
          siteUrl
          siteTitle
          socailAccounts {
            twitter
          }
        }
      }
    }
  `)

  const { siteTitle, siteDescription, siteUrl, socailAccounts } =
    site.siteMetadata

  const metaDescription = description || siteDescription
  const image =
    metaImage && metaImage.src
      ? `${siteUrl}${metaImage.src}/`
      : `${siteUrl}${defaultCard}`
  const canonical =
    slug && published ? `${siteUrl}/blog/${slug}/` : `${siteUrl}${url}`
  const metaUrl = url ? `${siteUrl}${url}` : `${siteUrl}/blog/${slug}/`

  return (
    <>
      <Helmet
        htmlAttributes={{ lang }}
        title={title}
        titleTemplate={`%s | ${siteTitle}`}
        link={
          canonical
            ? [{ rel: "canonical", key: canonical, href: canonical }]
            : []
        }
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          // {
          //   name: "keywords",
          //   content: keywords.join(","),
          // },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: "og:site_name",
            content: "مدونة بندر",
          },
          {
            property: `og:url`,
            content: metaUrl,
          },
          {
            property: "og:updated_time",
            content: "2020",
          },
          {
            property: "og:locale",
            content: "ar_AR",
          },

          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:creator`,
            content: socailAccounts.twitter,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ]
          .concat(
            metaImage
              ? [
                  {
                    property: "og:image",
                    itemprop: "image",
                    content: image,
                  },
                  {
                    property: "og:image:type",
                    content: "image/jpeg",
                  },

                  {
                    property: "og:image:alt",
                    content: "shared link image",
                  },

                  {
                    property: "og:image:width",
                    content: metaImage.width || "1200",
                  },
                  {
                    property: "og:image:height",
                    content: metaImage.height || "628",
                  },
                  {
                    name: "twitter:card",
                    content: "summary_large_image",
                  },
                ]
              : [
                  {
                    property: "og:image",
                    content: image,
                  },
                  {
                    property: "og:image:width",
                    content: 1200,
                  },
                  {
                    property: "og:image:height",
                    content: 628,
                  },
                  {
                    name: "twitter:card",
                    content: "summary_large_image",
                  },
                ]
          )
          .concat(meta)}
        script={[
          {
            type: "application/ld+json",
            innerHTML: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Article",
              mainEntityOfPage: `${
                url ? `${siteUrl}${url}` : `${siteUrl}/blog/${slug}/`
              }`,
              headline: `${title}`,
              description: `${metaDescription}`,
              image: {
                "@type": "ImageObject",
                url: `${image}`,
                height: 628,
                width: 1200,
              },
              inLanguage: "ar",
              url: `${url ? `${siteUrl}${url}` : `${siteUrl}/blog/${slug}/`}`,
              datePublished: `${published ? published : `2020`}`,
            }),
          },
        ]}
      >
        <link rel="shortcut icon" type="image/ico" href={icon} />
      </Helmet>
    </>
  )
}

Seo.defaultProps = {
  lang: `ar`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  canonical: PropTypes.string,
  lang: PropTypes.string,
  published: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
}

export default Seo
