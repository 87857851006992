export const toc = () => {
  const tocArray = []
  const allH2 = document.getElementsByTagName("h2")
  const hash = window.location.hash // return hash from url
  if (hash) {
    setTimeout(() => {
      document.querySelector(`${hash}`).scrollIntoView()
    }, 0)
  }

  for (let i = 0; i < allH2.length - 1; i++) {
    // -1 to remove finally tag in the end of every post
    const innerHtml = allH2[i].innerHTML
    const link = "headline-" + i
    allH2[i].classList.add("fff")
    allH2[i].id = link
    allH2[i].setAttribute("name", link)

    tocArray.push({
      content: innerHtml,
      link: link,
    })
  }
  return tocArray
}
