import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"
import Date from "../components/Date"
import RelatedPosts from "../components/RelatedPosts"
import PostTags from "../components/PostTags"
import ShareButtons from "../components/ShareButtons"
import PropTypes from "prop-types"
import Seo from "../components/seo"
import Toc from "../components/Toc"
// import CookieConsent from "react-cookie-consent"
// import { lightBox } from "../utils/lightBox"
import { toc } from "../utils/toc"
import rehypeRaw from "rehype-raw"
import rehypePrism from "@mapbox/rehype-prism"
import "prismjs/themes/prism-tomorrow.css"
import "prismjs/plugins/line-numbers/prism-line-numbers.css"

import "lazysizes"

export const postDetails = graphql`
  query ($slug: String!) {
    strapiPost(active: { eq: true }, slug: { eq: $slug }) {
      title
      active
      slug
      published(formatString: "MMM D YYYY")
      summary
      content
      shorturl
      cover {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 95
              placeholder: BLURRED
            )
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
      categories {
        name
      }
      related_posts {
        slug
        title
      }
    }
  }
`

const PostTemplate = ({ data }) => {
  const [tableOfContent, setTableOfContent] = useState([])
  const {
    title,
    published,
    summary,
    content,
    cover,
    slug,
    shorturl,
    categories,
    related_posts,
  } = data.strapiPost
  const coverImage = cover.localFile.childImageSharp.gatsbyImageData
  const image = cover ? cover.localFile.childImageSharp.resize : null

  useEffect(() => {
    if (typeof window !== "undefined") {
      // lightBox()
      setTableOfContent(toc())
    }
  }, [])

  return (
    <>
      {/* <CookieConsent
        location="bottom"
        buttonText="موافق"
        cookieName="Bandar Blog Cookies"
        containerClasses="cookies-container"
        contentClasses="cookies-content"
        buttonClasses="cookies-btn"
        expires={150}
        disableStyles={true}
        enableDeclineButton
        declineButtonText="إلغاء"
        declineButtonClasses="cookies-btn-cancel"
        setDeclineCookie
      >
        نحن نستخدم ملفات تعريف الارتباط على موقعنا على الإنترنت لأغراض التعريف
        والتحليل. باستخدام هذا الموقع فإنك توافق على تخزين وإمكانية وصول ملفات
        تعريف الارتباط على جهازك.
      </CookieConsent> */}
      <Seo
        title={title}
        description={summary}
        image={image}
        slug={slug}
        published={published}
      />

      <div className="columns is-centered">
        <article className="column width-restriction">
          <div className="columns mt-5 ml-0 mr-0 reverse-columns">
            <div className="column is-narrow pr-0 pb-0 pt-0 is-full-mobile">
              <Date published={published} />
            </div>
            <h1 className="column pr-0 pb-0 is-full-mobile post-template-title">
              {title}
            </h1>
          </div>
          <p className="post-template-summary">{summary}</p>

          <GatsbyImage
            className="cover"
            image={coverImage}
            loading="eager"
            fadeIn={false}
            alt={title}
          />

          {tableOfContent.length > 0 ? <Toc content={tableOfContent} /> : null}

          <div className="post-template-wrapper">
            <ReactMarkdown
              rehypePlugins={[rehypePrism, rehypeRaw]}
              children={content}
              components={{
                img: ({ node, ...props }) => {
                  const src = props.src
                  const alt = props.alt

                  if (src.split(".").pop() !== "gif") {
                    const searchTerm = "/"

                    const index = src.lastIndexOf(searchTerm)
                    const indexdot = src.lastIndexOf(".")

                    function replaceAt(str, index, ch) {
                      return str.replace(/./g, (c, i) => (i == index ? ch : c))
                    }

                    const smallImage = replaceAt(src, index, "/small_")
                    const mediumImage = replaceAt(src, index, "/medium_")
                    const largeImage = replaceAt(src, index, "/large_")
                    const thumbnail = replaceAt(src, index, "/thumbnail_")

                    const smallImagewebp = smallImage.replace(
                      smallImage.split(".").pop(),
                      "webp"
                    )

                    const thumbnailwebp = thumbnail.replace(
                      thumbnail.split(".").pop(),
                      "webp"
                    )

                    const largeImagewebp = largeImage.replace(
                      largeImage.split(".").pop(),
                      "webp"
                    )

                    const mediumImagewebp = mediumImage.replace(
                      mediumImage.split(".").pop(),
                      "webp"
                    )

                    return (
                      <div className="fade-box">
                        <img
                          src={thumbnailwebp}
                          data-src={largeImagewebp}
                          alt={alt}
                          className="lazyload blur-up"
                          data-srcset={`${smallImagewebp} 480w,
                      ${largeImagewebp} 800w`}
                          data-sizes="auto"
                          height="100%"
                        />
                      </div>
                    )
                  }
                  return (
                    <div className="fade-box">
                      <img
                        alt={alt}
                        data-src={src}
                        className="lazyload blur-up"
                      />
                    </div>
                  )
                },
              }}
            />
          </div>

          <PostTags data={categories} />

          <ShareButtons data={{ title, published, slug, summary, shorturl }} />

          <RelatedPosts posts={related_posts} />
        </article>
      </div>
    </>
  )
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PostTemplate
