import React from "react"
import { Link } from "gatsby"

const Toc = ({ content }) => {
  const toc = content
  return (
    <>
      <aside className="content-wrapper">
        <h1> المحتويات:</h1>
        <ol>
          {toc.map((headline, index) => {
            const { content, link } = headline
            return (
              <li>
                <Link to={`#${link}`} key={index}>
                  {content}
                </Link>
              </li>
            )
          })}
        </ol>
      </aside>
    </>
  )
}

export default Toc
