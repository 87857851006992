import React from "react"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import PropTypes from "prop-types"
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin"
import { FaTwitterSquare } from "@react-icons/all-files/fa/FaTwitterSquare"
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare"
import { FaWhatsappSquare } from "@react-icons/all-files/fa/FaWhatsappSquare"

import { useStaticQuery, graphql } from "gatsby"

const getSiteMeta = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

export const ShareButtons = ({ data }) => {
  const { site } = useStaticQuery(getSiteMeta)
  const { siteUrl } = site.siteMetadata
  const { title, slug, shorturl } = data
  const postUrl = shorturl || `${siteUrl}/${slug}`

  return (
    <section className="social-share-container">
      <hr />
      <p className="share-message">
        إذا أعجبك هذا المحتوى شاركه مع الآخرين لتحقيق أكبر استفادة ممكنة..
      </p>
      <FacebookShareButton url={postUrl} quote={title} className="social-share">
        <FaFacebookSquare className="icon-effects" />
      </FacebookShareButton>

      <TwitterShareButton className="social-share" url={postUrl} title={title}>
        <FaTwitterSquare className="icon-effects" />
      </TwitterShareButton>
      <LinkedinShareButton url={postUrl} className="social-share">
        <FaLinkedin className="icon-effects" />
      </LinkedinShareButton>

      <WhatsappShareButton url={postUrl} title={title} className="social-share">
        <FaWhatsappSquare className="icon-effects" />
      </WhatsappShareButton>
    </section>
  )
}

ShareButtons.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ShareButtons
