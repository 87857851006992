import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const PostTags = (tags) => {
  const { data } = tags

  return (
    <section>
      <p class="tags-title">تصنيفات</p>
      <hr className="short-hr" />
      <div className="post-tags">
        {data.map((tag, index) => {
          const url = tag.name.replace(" ", "-").toLowerCase()
          return (
            <Link to={`/tags/${url}/`} key={index} alt={tag.name}>
              {tag.name}
            </Link>
          )
        })}
      </div>
    </section>
  )
}

PostTags.propTypes = {
  tags: PropTypes.object,
}

export default PostTags
