import React from "react"
import PropTypes from "prop-types"

const Date = ({ published }) => {
  const DataArray = published.split(" ", 3)
  let month = ""
  switch (DataArray[0]) {
    case "Jan":
      month = "يناير"
      break
    case "Feb":
      month = "فبراير"
      break
    case "Mar":
      month = "مارس"
      break
    case "Apr":
      month = "أبريل"
      break
    case "May":
      month = "مايو"
      break
    case "Jun":
      month = "يونيو"
      break
    case "Jul":
      month = "يوليو"
      break
    case "Aug":
      month = "أغسطس"
      break
    case "Sep":
      month = "سبتمبر"
      break
    case "Oct":
      month = "أكتوبر"
      break
    case "Nov":
      month = "نوفمبر"
      break
    case "Dec":
      month = "ديسمبر"
      break
    default:
  }

  return (
    <>
      <div className="date-wrapper">
        <p className="day">{DataArray[1]}</p>
        <p className="month">{month}</p>
        <p className="year">{DataArray[2]}</p>
      </div>
    </>
  )
}

Date.propTypes = {
  published: PropTypes.string.isRequired,
}

export default Date
