import React from "react"
import { Link } from "gatsby"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const RelatedPosts = ({ posts }) => {
  return (
    <section>
      <hr />
      <h1> اقرأ أيضًا:</h1>
      <div className="related-posts-container">
        <div>
          <Link
            to={`/blog/${posts[0].slug}/`}
            className="link-no-effects animated-link"
            title={posts[0].title}
            onClick={(e) => {
              trackCustomEvent({
                category: `${posts[0].title}`,
                action: "Click",
                label: `Click on ${posts[0].title} page`,
              })
            }}
          >
            <span className="hover">{posts[0].title}</span>
          </Link>
        </div>
        <hr className="short-hr" />
        <div>
          <Link
            to={`/blog/${posts[1].slug}/`}
            className="link-no-effects animated-link"
            title={posts[1].title}
            onClick={(e) => {
              trackCustomEvent({
                category: `${posts[1].title}`,
                action: "Click",
                label: `Click on ${posts[1].title} page`,
              })
            }}
          >
            <span className="hover">{posts[1].title}</span>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default RelatedPosts
